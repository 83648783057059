<template>
  <div>
    <router-view></router-view>
    <van-tabbar v-model="barIndex">
      <van-tabbar-item icon="home-o" replace :to="href1">预约表单</van-tabbar-item>
      <van-tabbar-item icon="orders-o" replace :to="href2">预约查询</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "FybjIndex"
  ,created() {
    let path=this.$route.path
    if(path.indexOf('appointment')>0){
      this.barIndex=0
    }else{
      this.barIndex=1
    }
    let code = this.$route.query.code
    if(code){
      this.$store.commit('setCode',code);
    }
    this.href1=this.$store.state.href1
    this.href2=this.$store.state.href2
  },
  data() {
    return {
      barIndex: 0,
      href1:'',
      href2:''
    }
  },
}
</script>

<style scoped>

</style>
