import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
    href1:'/fybj/appointment',
    href2:'/fybj/appList'
}

const mutations = {
    setCode(state, code) {
        let baseHref1='/fybj/appointment';
        let baseHref2='/fybj/appList';
        state.href1=baseHref1+"?code="+code
        state.href2=baseHref2+"?code="+code
    }
}

export default new Vuex.Store({
    state,mutations
})