<template>
  <div>
    <van-image
        width="100%"
        height="300px"
        :src="pageconfig.imagePath"
    />
    <van-form @submit="onSubmit">
      <van-field
          v-model="addForm.username"
          required
          label="姓名"
          placeholder="请输入您的姓名"
      />
      <van-cell is-link title="性别" required v-model="pageconfig.genderTxt" @click="pageconfig.genderSelect= true" />
      <van-action-sheet v-model="pageconfig.genderSelect" :actions="pageconfig.genderActions" @select="genderSelect" />
      <van-field
          v-model="addForm.id_card"
          label="身份证"
          placeholder="请输入您的身份证"
      />
      <van-field type="tel"
          v-model="addForm.phone"
          required
          label="电话号码"
          oninput = "value=value.replace(/[^\d]/g,'')" maxlength="11"
          placeholder="请输入您的电话号码"
      />
      <van-cell is-link title="预约选项" required v-model="pageconfig.app_option_txt" @click="pageconfig.app_select= true"/>
      <van-action-sheet v-model="pageconfig.app_select" :actions="pageconfig.appActions" @select="optionSelect" />

      <van-cell title="预约时间" is-link required @click="showAppTime" v-model="pageconfig.app_time_txt"/>
      <van-popup v-model="pageconfig.app_time_visable" position="bottom" :style="{ height: '50%' }" >
        <div style="border-bottom: 1px solid #e3e3e3;padding:10px 5px 5px 20px;">
          <div style="height: 25px;line-height: 25px;font-weight: bold;">预约</div>
          <div style="height: 25px;line-height: 25px;font-size: 14px;">为了更好的服务，您可以提前一周预约</div>
        </div>
        <van-tabs :ellipsis="false" :line-width="60" :animated="true" class="selectTab" @disabled="onClickDisabled" @click="onClickTab" @rendered="onClickTab">
          <van-tab  v-for="item in pageconfig.timeList"  :key="item.pid" v-bind:disabled="bindDisabled(item)">
            <template #title>
              <div style="margin: 10px;padding:5px 10px;" :class="tabStyle(item)" >
                <div style="height: 20px;">{{item.app_date.substr(5,5)}}</div>
                <div style="height: 20px;">{{item.app_week}}</div>
              </div>
            </template>
            <van-grid :border="false">
              <van-grid-item  v-for="children in item.children" :key="children.id"  @click="timeClick(children.id)">
                <div style="width: 60px;background: #e0f2f1;padding: 8px 20px;text-align: center;border-radius: 5px;font-size: 15px;">
                  <div style="height: 20px;line-height: 20px;">{{children.app_hour.substr(0,5)}}</div>
<!--                  <div style="height: 20px;line-height: 20px;">{{children.left_times}}</div>-->
                </div>
              </van-grid-item>
            </van-grid>
          </van-tab>
        </van-tabs>
      </van-popup>

      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {Notify, Toast} from 'vant';
import PublicHttp from "../../utils/public-api";

export default {
  name: "Appointment"
  ,created() {
    Toast.setDefaultOptions({ duration: 3500 })
    this.initData();
    this.getAppTimes();
    let code = this.$route.query.code
    if(localStorage.getItem("fybj_openid") == null){
     if(code!=null){
        this.getUserMess(code)
        }else{
          Notify('加载错误！');
        }
   }
   this.pageconfig.ourUser=true
  }
  ,data(){
    return{
      addForm:{
        username:''
        ,gender:''
        ,id_card:''
        ,app_option:''
        ,option_name:''
        ,app_date:''
        ,phone:''
        ,app_time:''
        ,openid: ''
        ,app_time_id:''
      },
      pageconfig:{
        ourUser:false,
        imagePath:''
        ,genderTxt:''
        ,genderSelect:false
        ,genderActions: [{ name: '男' ,value:1}, { name: '女',value: -1 }]
        ,app_option_txt:''
        ,app_select:false
        ,appActions: [],
        timeList:[],
        app_date_visable:false
        ,app_date:new Date()
        ,app_time_visable:false
        ,app_time_txt:''
        ,loading:false
        ,timeGrid:[]
      }

    }
  },
  methods:{
    onSubmit(){
      if(this.addForm.username==''){
        Notify('请输入您的姓名 ')
        return false
      }
      if(this.addForm.gender==''){
        Notify('请选择性别')
        return false
      }
      if(this.addForm.phone==''){
        Notify('请输入电话号码')
        return false
      }
      if(this.addForm.app_option==''){
        Notify('请选择预约选项')
        return false
      }
      if(this.addForm.app_date==''){
        Notify('请选择预约日期')
        return false
      }
      if(this.addForm.app_time==''){
        Notify('请选择app_time')
        return false
      }
      // let openId='1542154863'
      let openId=localStorage.getItem('fybj_openid')
      if(openId===undefined||openId===null||openId===''){
        Notify('请正确进入！');
        return  false;
      }
      this.addForm.openid=openId
      PublicHttp.AddAppointMent('index/addAppointment','post',this.addForm).then(resp=>{
        let res=resp.data
        if(res.code==200){
          Toast.success('预约成功');
        }else{
          Toast.fail(resp.data.message);

        }
      });

    },
    initData(){
      let param={types:'APP_SERVERS'}
      PublicHttp.getParams('index/getParams','get',param).then(resp=>{
        let res=resp.data
        if(res.code==200){
          this.pageconfig.appActions=res.data
        }
      });

      param={types:'SWIPER_IMG'}
      PublicHttp.getParams('index/getParams','post',param).then(resp=>{
        let res=resp.data
        if(res.code==200){
          this.pageconfig.imagePath=res.data[0].path
        }
      });
    },
    genderSelect(item){
      this.pageconfig.genderTxt=item.name
      this.addForm.gender=item.value
      this.pageconfig.genderSelect=false;
    }
    ,optionSelect(item){
      this.pageconfig.app_option_txt=item.name
      this.addForm.app_option=item.value
      this.addForm.option_name=item.name
      this.pageconfig.app_select=false;
    }
    ,showAppTime(){
      this.pageconfig.app_time_visable=true
    },
    getUserMess(code){
      let param={code:code}
      PublicHttp.getUserMess('index/getUserMess','get',param).then(resp=>{
        let res=resp.data
        if(res.code==200){
          let wxRes=res.data
          localStorage.setItem('fybj_openid',wxRes.openid)
        }
      });
    },
    getAppTimes(){
      PublicHttp.getAppTimes('index/getAppTimes',null,null).then(resp=>{
        let res=resp.data
        if(res.code==200){
          this.pageconfig.timeList=res.data
        }
      });
    }
    ,tabStyle(row){
      if(row.is_work==="0"){
        return 'tab_dark'
      }else{
        return  ''
      }
    },
    bindDisabled(row){
      if(row.is_work==="0"){
        return true
      }else{
        return  false
      }
    },
    onClickDisabled(index) {
      let item=this.pageconfig.timeList[index];
      this.pageconfig.timeGrid=item;
      Toast(item.app_date+' 日无法预约！(休息)');
    },
    onClickTab(index){
      this.pageconfig.timeGrid=this.pageconfig.timeList[index];
    },
    timeClick(id){
      let children=this.pageconfig.timeGrid.children
      this.addForm.app_time_id=id
      let app_hour=children[id].app_hour
      let time=app_hour.substr(0,5)
      let date=children[id].app_date
      this.addForm.app_time=time
      this.addForm.app_date=date
      this.pageconfig.app_time_txt=date+' '+time
      this.pageconfig.app_time_visable=false
    }
  }

}
</script>

<style scoped>
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

.selectTab /deep/ .van-tabs--line {
  height: 60px;
}

.selectTab /deep/ .van-tabs__wrap {
  height: 60px;
}

.tab_dark{
  color: #bab0b0ab;
}
</style>
