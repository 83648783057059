import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import routers from "@/router";
import moment from 'moment'
import Vant from 'vant';
import 'vant/lib/index.css';
import store  from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });

Vue.use(Vant);

Vue.config.productionTip = false

Vue.prototype.$monent=moment
Vue.filter('moment', function (value, formatString) {
  formatString = formatString || 'YYYY-MM-DD';
  if(value==null||value==''){
    return ''
  }else{
    return moment(value*1000).format(formatString);
  }
});
// Vue.use(WotDesign)
Vue.use(VueRouter)
const  myRouter=new VueRouter({
  mode:'history',
  routes:routers
})

myRouter.beforeEach((to, from, next) => {
  document.getElementById('public_title').innerHTML = to.meta.title === undefined ? '徽州区妇幼表单预约' : to.meta.title
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let mytoken=localStorage.getItem('mytoken')
    try {
      mytoken=JSON.parse(mytoken)
    }catch (error){
      mytoken= null
    }
    if(mytoken){
      let date=new Date().getTime()//ms
      let ls_time=mytoken.ls_time*1000//
      if(date-mytoken.startTime>ls_time){
        localStorage.removeItem('mytoken');
        this.$message.alert('登录信息已过期');
        next({
          path: '/health/login/index',
          query: { redirect: to.fullPath }
        })
      }else{
        next()
      }
    }else {
      next({
        path: '/health/login/index',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next() // 确保一定要调用 next()
  }
})

new Vue({
  router:myRouter,
  store,
  render: h => h(App),
}).$mount('#app')
