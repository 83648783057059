import Appointment from "@/components/Appointment";
import AppointList from "@/components/AppointList";
import FybjIndex from "@/components/FybjIndex";
import Login from "@/components/admin/Login";
import  Index from "@/components/admin/Index";

const  routers=[
    {
        path:'/index.html'
        ,redirect:'/fybj/appointment'
    },
    {
        path:'/'
        ,redirect:'/fybj/appointment'
    },
    {
        path:'/fybj'
        ,name:'FybjIndex'
        ,component: FybjIndex
        ,meta:{
            title:'徽州区妇幼表单预约'
        }
        ,children:[
            {
                path:'appointment'
                ,name:'appointment'
                ,component: Appointment
                ,meta:{
                    title:'徽州区妇幼表单预约'
                }
            },
            {
                path:'appList'
                ,name:'AppointList'
                ,component: AppointList
                ,meta:{
                    title:'预约记录'
                }
            }
        ]

    }
    ,{
        path:'/health/login/index'
        ,name:'login'
        ,component: Login
        ,meta:{
            title:'管理员登录'
        }
    },{
        path:'/health/index'
        ,name:'index'
        ,component: Index
        ,meta:{
            title:'管理后台',
            requiresAuth: true
        }
    }
]

export  default  routers

