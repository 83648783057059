<template>
  <div>
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
    >
      <van-cell  v-for="item in list" :key="item" @click="itemClick(item)">
        <van-row>
          <van-col span="20"><span style="font-weight: bold">预约项目：</span>{{item.app_option_title}}</van-col>
        </van-row>
        <van-row>
          <van-col span="20"><span style="font-weight: bold">创建日期：</span>{{$monent(item.create_time).format('yyyy-MM-DD HH:mm:ss')}}</van-col>
        </van-row>
        <van-row>
          <van-col span="20"><span style="font-weight: bold">预约时间：</span>{{item.app_date.substr(0,10)+' '+item.app_time}}</van-col>
        </van-row>
        <van-row>
          <van-col span="20"><span style="font-weight: bold">就诊时间：</span>{{item.start_time==null?'':$monent(item.start_time).format('yyyy-MM-DD')}}</van-col>
        </van-row>
        <van-row>
          <van-col span="20"><span style="font-weight: bold">结束时间：</span>{{item.end_time==null?'':$monent(item.end_time).format('yyyy-MM-DD')}}</van-col>
        </van-row>
        <van-row>
          <van-col span="20"><span style="font-weight: bold">就诊状态：</span>{{item.app_status}}</van-col>
        </van-row>
        <vam-row>
          <van-col span="20"><span style="font-weight: bold">医生回复：</span>{{item.doc_message}}</van-col>
        </vam-row>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import PublicHttp from "../../utils/public-api";
import {Notify, Toast} from "vant";

export default {
  components: {
  },
  created() {
    this.loadList();
  }
  ,data(){
    return{
      list:[]
      ,loading:false
      ,finished:true
      ,params:{
        openid:''
      }
    }
  }
  ,methods:{
     loadList(){

       // this.params.openid='1542154863'
       // if(this.params.openid!=null){localStorage.fybj_openid
        if(localStorage.getItem('fybj_openid')!=null){
         this.params.openid=localStorage.getItem('fybj_openid')
         PublicHttp.getAppList('index/getAppList','get',this.params).then(resp=>{
           let res=resp.data
           if(res.code==200){
             Toast('数据加载成功')
             this.finished=true
             this.list=res.data
           }
         });

       }

    },
    itemClick(item){
       Notify(item.id)
       // console.log(item.id);
    }
  }
}
</script>
