import axios from 'axios'
//2、创建axios的实例
let httpService = axios.create({
    baseURL:'https://fybj.hshyg.top/public/index.php/',
    // baseURL:'/api',
    timeout: 5000,   //超时配置 3秒
    responseType: 'json',   // 响应数据格式
    responseEncoding: 'utf8',  // 响应数据编码
    // baseUrl: process.env.NODE_ENV === 'dev' ? '/dev' : '/api',// TODO:具体的配置可以根据项目情况而来
    // timeout:5000
})
//3、axios的拦截--request
httpService.interceptors.request.use(config => {
    // 请求成功处理
    if(localStorage.getItem('token')){//判断浏览器中的cookie中是否存在项目的token
        config.headers.token = localStorage.getItem('token')
    }
    return config;
},err => {
    Promise.reject(err);// 请求错误处理
})

//4、axios的拦截--response
httpService.interceptors.response.use(response => {
    return response;
},err => {
    return Promise.reject(err);
})

//5、get请求的封装
function get(url,params={}){
    return new Promise((resolve,reject) => {
        httpService({
            url:url,
            method:'get',
            params:params
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

//6、post请求封装  默认json格式:'Content-Type':'application/json',如果是文件上传，可以修改headers为 headers: { 'Content-Type': 'multipart/form-data' }
function post(url,params={},headers={'Content-Type':'application/json'}){
    return new Promise((resolve,reject) => {
        httpService({
            url:url,
            method:'post',
            data:params,
            headers: headers
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

export function request(url,method,params){
    if(method==='post'||method==='POST'){
        return post(url,params)
    }else {
        return get(url,params)
    }
}

//7、将模块暴露
// export default{
//     get,
//     post
// }
