<template>
  <el-container>
    <el-aside width="200px">
      <el-row style="line-height: 60px;text-align: center;">
        <el-col>
          <a style="display: inline;line-height: 20px;font-weight: bold;font-size: 15px;width: 200px;color: white;">徽州区妇幼保健后台</a>
        </el-col>
      </el-row>
      <el-scrollbar class="_scroll">
        <el-menu
            class="_layout-nav"
            :default-active="$route.path"
            :default-openeds="editableTabsValue"
            router
            ref="menu"
            style="border:none"
            @select="select"
            :collapse="isCollapse"
        >
          <MenuTree
              v-for="menu in routers"
              :menus="menu.children||[]"
              :key="menu.path"
          ></MenuTree>
        </el-menu>
      </el-scrollbar>

    </el-aside>
    <el-container>
      <el-header>Header</el-header>
      <el-main>
        <el-tabs
            v-model="editableTabsValue"
            @tab-remove="removeTab"
        >
          <el-tab-pane
              v-for="item in editableTabs"
              :key="item.name"
              :label="item.title"
              :name="item.name"
              :closable="item.close"
          >
            <tab-component :is="item.content" :objId="item.name"></tab-component>
          </el-tab-pane>
        </el-tabs>
      </el-main>
      <el-footer style="color: white;">
          <el-link target="_blank" style="color: white;">版权所有 COPYRIGHT@徽州区妇幼保健</el-link>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import DashBord from "@/views/dashbord/DashBord";
import Vue from "vue";
import MenuTree from "../../views/plugins/MenuTree";
export default {
  name: "Index",
  components: {MenuTree},
  created() {
    this.init();
  },
  data(){
    return{
      isCollapse: false,
      editableTabsValue: "1",
      editableTabs: [
        {
          title: "首页",
          name: "1",
          content: DashBord,
          close: false
        }
      ],
      tabIndex: 1,
      dialogVisible: false, //添加新标签dialog状态
      addTagsValue: "",
      routers:[]
    }
  },
  methods:{
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    init(){

    },
    select(){
      this.routers=[
        {'id':'1',title:'首页','path':'/user/User',parent_id:null},
        {'id':'2',title:'首页2','path':'/user/User',parent_id:null},
        {'id':'3',title:'首页3','path':'/user/User',parent_id:null,
        children:[
          {'id':'4',title:'首页3-1','path':'/user/User',parent_id:null},
          {'id':'5',title:'首页3-2','path':'/user/User',parent_id:null},
          {'id':'6',title:'首页3-3','path':'/user/User',parent_id:null}]
        }
      ]
    },
    addTab(row){
      let path='../../views/'+row
      let component=Vue.component(
          'tab-component',
          // 这个动态导入会返回一个 `Promise` 对象。
          () => import(path)
      )
      let title = "ssss";
      let newTabName = ++this.tabIndex + "";
      this.editableTabs.push({
        title: title, //标签名
        name: newTabName,
        content: component, //对应组件名称
        close: true
      })
      this.editableTabsValue = newTabName;
    },
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter(tab => tab.name !== targetName);
    }
  }
}
</script>

<style scoped>
    .el-container{
      /*设置内部填充为0，几个布局元素之间没有间距*/
      padding: 0px;
      /*外部间距也是如此设置*/
      margin: 0px;
      /*统一设置高度为100%*/
      height: 100%;
    }

    .el-header, .el-footer {
      background-color: #545c64;
      text-align: center;
      line-height: 60px;
    }

    .el-aside {
      background-color: #545c64;
    }

    .el-main {
      padding: 0px;
    }

    body > .el-container {
      margin-bottom: 40px;
    }

    .el-container:nth-child(5) .el-aside,
    .el-container:nth-child(6) .el-aside {
      line-height: 260px;
    }

    .el-container:nth-child(7) .el-aside {
      line-height: 320px;
    }

    .el-tabs__nav .el-tabs__item:nth-child(1) span{
      display: none;
    }
</style>
