<template>
  <el-container>
    <el-main style="margin-top: 150px;" >
      <el-card shadow="always" align="middle" class="box-card" style="width: 400px;margin: 0 auto;">
        <div slot="header" class="clearfix">
          <span>登录</span>
        </div>
        <el-form ref="form1" :model="loginForm" :rules="rules"  label-width="90px" align="middle" style="width: 300px;">
          <el-form-item label="用户名" prop="user_name">
            <el-input v-model="loginForm.user_name" ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="pass_word">
            <el-input v-model="loginForm.pass_word" show-password></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="valid_code">
            <el-col :span="14">
              <el-form-item>
                <el-input v-model="loginForm.valid_code" style="width: 120px;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="6">
              <el-form-item >
                <el-image :src="pageConfig.captchPath" @click="getValidImage" style="width:100px;height: 30px;cursor: pointer"></el-image>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label-width="0px">
            <el-button type="primary" @click="doLogin('form1')">登录</el-button>
            <el-button @click="toRegister">注册</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-main>
    <el-footer style="background: #2c3e50;text-align: center;line-height: 40px;">
      <div>
        <el-link href="https://element.eleme.io" target="_blank">默认链接</el-link>
        <el-link type="primary">主要链接</el-link>
        <el-link type="success">成功链接</el-link>
        <el-link type="warning">警告链接</el-link>
        <el-link type="danger">危险链接</el-link>
        <el-link type="info">信息链接</el-link>
      </div>
    </el-footer>
  </el-container>

</template>

<script>
import PublicHttp from "../../../utils/public-api";
export default {
  name: "Login"
  ,created() {
    this.init()
  }
  ,data(){
    return{
      loginForm:{
        user_name:''
        ,pass_word:''
        ,valid_code:''
      },
      rules:{
        user_name:[{ required: true, message: '请输入用户名', trigger: 'blur' }]
        ,pass_word:[{ required: true, message: '请输入密码', trigger: 'blur' }]
        ,valid_code:[{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      pageConfig:{
        captchPath:''
      }
    }
  }
  ,methods:{
    init(){
      this.pageConfig.captchPath=this.getValidImage()
    },
    doLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          PublicHttp.doLogin('Login/doLogin','post',this.loginForm).then(res=>{
            if(res.data.code==200){
              let paramss = {name: 'token', value: res.data.mtoken,ls_time:res.data.pst};
              var datas = Object.assign(paramss, { startTime: new Date().getTime() });
              localStorage.setItem("mytoken", JSON.stringify(datas));
              this.$router.push('/health/index')
            }else{
              this.$message.error(res.data.message)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    toRegister() {
      this.$router.push('/register')
    },
    getValidImage(){
      PublicHttp.getValidImage('Login/getValidImage').then(resp=>{
        if(resp.status==200){
         this.pageConfig.captchPath=resp.data
        }
      })
    }
  }
}
</script>

<style scoped>
.el-container{
  /*设置内部填充为0，几个布局元素之间没有间距*/
  padding: 0px;
  /*外部间距也是如此设置*/
  margin: 0px;
  /*统一设置高度为100%*/
  height: 100%;
}
</style>
