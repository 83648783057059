import {request} from './request-axios'

let PublicHttp={
    getValidImage(url,method,params){
        return request(url,method,params)
    },
    doLogin(url,method,params){
        return request(url,method,params)
    },
    getAppList(url,method,params){
        return request(url,method,params)
        // return request(url,method,params)
    },
    getParams(url,method,params){
        return request(url,method,params)
    },
    getUserMess(url,method,params){
        return request(url,method,params)
    },
    getAppTimes(url,method,params){
        return request(url,method,params)
    },
    AddAppointMent(url,method,params){
        url=url+'?XDEBUG_SESSION_START=15211';
        return request(url,method,params)
    },
    caculateDays(end,start) {
        return Math.abs(parseInt((new Date(end).getTime() - new Date(start).getTime()) / (1000 * 60 * 60 * 24)));
    },
    localRead(key){
        return localStorage.getItem(key) || ''
    }
}

export  default  PublicHttp
